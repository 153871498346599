// import layout from './layout';
// import text from './text';
import colours from './colours';
import { StyleSheet} from 'react-native';
import { vw, vh, vmin, vmax } from 'react-native-expo-viewport-units';
// import * as Font from 'expo-font';

const styles = StyleSheet.create({
    h1: {
        fontFamily: 'Raleway, sans-serif',
        fontSize: 36,
        color: 'white',
        fontWeight: '800',
        letterSpacing: 0.2,
        opacity: 0.94,
        margin: '40px',
        textAlign: 'center'
    },
    h2: {
        fontFamily: 'Raleway, sans-serif',
        fontSize: 26,
        marginBottom: 20,
        fontWeight: '600'
    },
    h3: {
        fontFamily: 'Raleway, sans-serif',
        fontSize: 24,
        fontWeight: '150'
    },
    h4: {
        fontFamily: 'Raleway, sans-serif',
        fontSize: 18,
        fontColor: 'rgb(50,50,50)'
    }, 
    h5: {
        fontFamily: 'Raleway, sans-serif',
        fontWeight: '600',
        fontSize: '14px',
        color: colours.textBlue,
        marginHorizontal: '9px'
    },
    subHeading: {
        fontFamily: 'Raleway, sans-serif',
        fontSize: 14,
        fontStyle: 'italic'
    },
    p: {
        fontFamily: 'Raleway, sans-serif',
        color: colours.textBlue,
        fontSize: 14,
        marginHorizontal: '9px'
    },
    nav: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: vw(100),
        height: 50,
        backgroundColor: colours.primary,
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9
    },
    datePicker: {
        width: '98%',
        height: '100%'
    },
    footer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: vw(100),
        height: 50,
        backgroundColor: 'lightgrey',
        justifyContent: 'center',
        alignItems: 'center',
    },
    footerClear: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: vw(100),
        height: 50,
        backgroundColor: 'none',
        justifyContent: 'center',
        alignItems: 'center',
    },
    footerContainer: {
        position: 'absolute',
        bottom: 50,
        height: 70,
        left: 0,
        width: vw(100)
    },
    footerButton: {
        height: 70,
        justifyContent: 'space-evenly'
    },
    activeFooterButton : {
        height: 70,
        justifyContent: 'space-evenly',
        backgroundColor: 'rgba(10,200,10,.05)'
    },
    footerHeading: {
        fontFamily: 'Raleway, sans-serif',
        color: colours.textBlue,
        fontSize: 14,
        fontWeight: '500',
        textAlign: 'center'
    },
    link: {
        textDecorationLine: 'underline'
    },
    fullScreenView: {
        minHeight: vh(100),
        width: vw(100),
        flexGrow: 1,
        backgroundColor: colours.gradientGrey,
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingVertical: 80
    },
    landingfullScreenView: {
        minHeight: vh(100),
        width: vw(100),
        flexGrow: 1,
        backgroundColor: colours.gradientGrey,
        alignItems: 'center',
        justifyContent: 'flex-start'
    },
    mainScrollContainer: {
        marginTop: '0px', 
        maxHeight: '80vh',
        width: vw(100),
        paddingTop: vh(0),
        paddingBottom: vh(12),
        flex: 1
    }, 
    primaryButton: {
        width: 160,
        height: 40,
        borderRadius: 4,
        backgroundColor: colours.primary,
        justifyContent: 'center',
        alignItems: 'center',
        marginVertical: 20
    },
    primaryButtonText: {
        fontFamily: 'Raleway, sans-serif',
        color: '#FFF',
        fontSize: 18,
        textAlign: 'center'
    },
    menuButton: {
        width: 309,
        height: 90,
        borderRadius: 5,
        backgroundColor: colours.primary,
        justifyContent: 'center',
        alignItems: 'center',
        margin: 0,
        padding: 0,
        overflow: 'hidden'
    },
    switchButton: {
        width: 200,
        height: 60,
        borderRadius: 5,
        backgroundColor: colours.primary,
        justifyContent: 'center',
        alignItems: 'center',
        margin: 0,
        padding: 0,
        overflow: 'hidden'
    },
    menuButtonText: {
        fontFamily: 'Raleway, sans-serif',
        color: colours.textBlue,
        fontWeight: '600',
        fontSize: 21
    },
    coachJobButton: {
        position: 'relative',
        top: '3px',
        height: '75px',
        width: '35%',
        borderRadius: 4,
        backgroundColor: colours.primary,
        justifyContent: 'center',
        alignItems: 'center',
        marginVertical: 20
    },
    coachJobButtonPlaceholder: {
        position: 'relative',
        top: '3px',
        height: '75px',
        width: '35%',
        borderRadius: 4,
        backgroundColor: colours.secondary,
        color: '#FFF',
        border: '1px solid gray',
        justifyContent: 'center',
        alignItems: 'center',
        marginVertical: 20,
        cursor: 'none'
    },
    coachJobButtonText: {
        fontFamily: 'Raleway, sans-serif',
        color: '#FFF',
        fontSize: 16,
        textAlign: 'center'
    },
    jobButton: {
        width: '120px',
        borderRadius: 4,
        backgroundColor: colours.primary,
        justifyContent: 'center',
        alignItems: 'center',
        margin: 5
    },
    jobButtonPlaceholder: {
        width: '120px',
        borderRadius: 4,
        backgroundColor: colours.secondary,
        border: '1px solid gray',
        justifyContent: 'center',
        alignItems: 'center',
        marginVertical: 5,
        cursor: 'none'
    },
    jobButtonRed: {
        width: '120px',
        borderRadius: 4,
        backgroundColor: "red",
        border: '1px solid gray',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 5
    },
    jobButtonText: {
        fontFamily: 'Raleway, sans-serif',
        color: '#FFF',
        fontSize: 12,
        textAlign: 'center'
    },
    formInputContainer: {
        width: vw(80),
        maxWidth: '720px',
        marginVertical: 3,
        paddingHorizontal: 2
    },
    formInput: {
        fontFamily: 'Raleway, sans-serif',
        fontSize: 18,
        width: '98%',
        height: 33,
        backgroundColor: 'rgba(230,230,230,.4)',
        borderRadius: 5,
        padding: 8,
        borderWidth: 2,
        borderColor: colours.primary,
        marginVertical: 7,
    },
    formPicker: {
        fontFamily: 'Raleway, sans-serif',
        fontSize: 16,
        width: '98%',
        height: 40,
        backgroundColor: 'rgba(230,230,230,.4)',
        borderRadius: 5,
        padding: 8,
        borderWidth: 2,
        borderColor: colours.primary,
        marginVertical: 7
    },
    jobTypePicker: {
        fontFamily: 'Raleway, sans-serif',
        fontSize: 16,
        width: '59%',
        height: 40,
        backgroundColor: 'rgba(230,230,230,.4)',
        borderRadius: 5,
        paddingVertical: 2,
        paddingLeft: 4,
        paddingRight: 0,
        borderWidth: 2,
        borderColor: colours.primary,
        marginVertical: 7
    },
    textInput: {
        fontFamily: 'Raleway, sans-serif',
        fontSize: 18,
        width: '98%',
        minHeight: 150,
        height: 'auto',
        backgroundColor: 'rgba(230,230,230,.4)',
        borderRadius: 5,
        padding: 8,
        borderWidth: 2,
        borderColor: colours.primary,
        marginVertical: 7
    },
    JobView: {
        marginVertical: '20px',
        marginHorizontal: '20px',
        width: vw(80),
        minHeight: '150px',
        maxWidth: '400px',
        backgroundColor: '#fff',
        height: 'auto',
        minHeight: '221px',
        boxShadow: '1px 1px',
        borderRadius: 5,
        position: 'relative'
    },
    coachJobView: {
        marginVertical: '20px',
        marginHorizontal: '20px',
        width: vw(80),
        minHeight: '150px',
        maxWidth: '400px',
        backgroundColor: '#fff',
        height: 'auto',
        boxShadow: '1px 1px',
        border: '1px solid',
        borderRadius: 5
    },
    separatorHigh: {
        height: '2px',
        backgroundColor: colours.primary,
        marginVertical: '6px',
        width: '78%'
    },
    separatorMid: {
        height: '2px',
        backgroundColor: colours.primary,
        marginVertical: '6px',
        width: '61%'
    },
    separatorLow: {
        height: '2px',
        backgroundColor: colours.primary,
        marginVertical: '8px',
        width: '45%'
    },
});

export default styles;