import axios from 'axios';
import conf from './apiconfig.json';
import { navigate } from "gatsby";

const User = {
    load: () => {
        let user
        if (typeof window !== 'undefined' && window.localStorage) user = localStorage.getItem('user');
        if (user) {
            try {
                return JSON.parse(user);
            } catch (err) {
                console.log('Failing to get user')

                // this is in case localstorage is misformatted
                localStorage.removeItem("user")

                navigate('/')
                console.log(err);
            }
        }
        return null;
    },
    me: async (jwt) => {
        if (!jwt) return navigate('/')
        let response
        
        try {
            response = await axios.get(conf.strapiUrl + '/users/me', {
                headers: { Authorization: 'Bearer ' + jwt },
            })
        } catch(e) {
            // this is in case a token expires while in localstorage
            localStorage.removeItem("user")
            navigate("/")
        }

        localStorage.setItem('user', JSON.stringify({ ...response.data, jwt }));
        console.log(response.data);
        return { ...response.data, jwt };
    },
    find: async (id, jwt) => {
        let response = await axios.get(conf.strapiUrl + '/users/' + id, {
            headers: { Authorization: 'Bearer ' + jwt },
        });
        return response.data;
    },
    logout: () => {
        localStorage.removeItem('user');
        return User.load();
    },
    // Sends link to go to reset password page
    forgotPassword: async (email) =>
        axios
            .post(`${conf.strapiUrl}/auth/forgot-password`, {
                email: email,
                url: `http:/localhost:1337/forgotpassword/`,
            })
            .then(response => {
                // Handle success.
                console.log('User received an email');
                return response;
            }, error => {
                // Handle error.
                console.log('An error occurred:', error);
                return error.response;
            }),
    // send reset password request
    resetPassword: async (password, code) =>
        axios
            .post(`${conf.strapiUrl}/auth/reset-password`, {
                code: code,
                password: password,
                passwordConfirmation: password,
            })
            .then(response => {
                // Handle success.
                console.log('Password successfully reset');
                return response;
            }, error => {
                // Handle error.
                console.log('An error occurred:', error);
                return error;
            }),
    login: async (body) => {
        // body = { identifier: "email / username", password: "password" }
        console.log('calling login')
        return axios
            .post(conf.strapiUrl + '/auth/local', body)
            .then(async response => {
                console.log(response);
                return User.me(response.data.jwt)
                }, (error) => {
                console.log(error);
                console.log(error.response);
                return error.response;
                }
            )
    },
    register: async (body, certificates) => {
        // body = { email: "email", password: "password", ...allOtherOptionalData }
        let responseObj = {};
        if (certificates !== null && certificates[0]) {
            console.log('certificates present')
            return axios
            .post(conf.strapiUrl + '/auth/local/register/', body)
            .then(response => {
                console.log('RESPONSE SUCCESSFUL: ', response)
                // Setting response to user obj to call User.Me after posting certificate images
                responseObj = response;
                return response;
            })
            .then(response => {
                let userID = response.data.user.id;
                console.log('USER ID: ', userID);
                console.log('THROUGH TO SECOND POST REQ');
                const formData = new FormData();
                formData.append('refId', userID);       
                formData.append('ref', 'user');
                formData.append('source', 'users-permissions');
                formData.append('field', 'certificates');
                // console.log('Certificates length: ', )
                if (certificates.length > 1) {
                    console.log('length > 1')
                    for (let n = 0; n < certificates.length; n++) {
                        // formData.append('files.certificates',certificates[n], `${userID} Cert #${n}`);
                        formData.append('files', certificates[n], certificates[n].name);
                    }
                }
                console.log(certificates[0])
                if (certificates.length === 1) {
                    console.log('length = 1')
                    // formData.append('files.certificates', certificates[0], `${userID} Cert #1`);
                    formData.append('files', certificates[0], certificates[0].name);
                }
                // formData.append('files', certificates[0])
                return axios.post(conf.strapiUrl + '/upload', formData)
            })
            .catch(error => {
                console.log('CATCHING ERROR')
                console.log(error);
                return error.response;
            })
        } else {
            console.log(body)
            return axios
            .post(conf.strapiUrl + '/auth/local/register/', body)
            .then(async response => {
                return User.me(response.data.jwt)
            }, error => {
                console.log(error);
                return error.response;
            })
        }
    },
    update: async (userid, body, jwt, certificates) => {
        console.log(certificates)
        if (certificates !== null && certificates[0]) {
            return axios
            .put(conf.strapiUrl + `/users/${userid}`, body, {
                headers: { Authorization: 'Bearer ' + jwt },
            })
            .then(response => {
                console.log(response)
            
                let userID = response.data.id;
                console.log('USER ID: ', userID)
                console.log('THROUGH TO SECOND POST REQ')
                const formData = new FormData();
                formData.append('refId', userID);  
                formData.append('ref', 'user');
                formData.append('source', 'users-permissions');
                formData.append('field', 'certificates');
                if (certificates.length > 1) {
                    console.log('length > 1')
                    for (let n = 0; n < certificates.length; n++) {
                        // formData.append('files.certificates',certificates[n], `${userID} Cert #${n}`);
                        formData.append('files', certificates[n], certificates[n].name);
                    }
                }
                if (certificates.length === 1) {
                    console.log('length = 1')
                    // formData.append('files.certificates', certificates[0], `${userID} Cert #1`);
                    formData.append('files', certificates[0], certificates[0].name);
                }
                // formData.append('files', certificates[0])
                return axios
                .post(conf.strapiUrl + '/upload', formData)
            })
            .then(User.me(jwt))
            .catch(error => {
                console.log(error);
                return error;
            })
        }
        else {
        return axios
            .put(conf.strapiUrl + `/users/${userid}`, body, {
                headers: { Authorization: 'Bearer ' + jwt },
            })
            .then(User.me(jwt))
            .catch(error => {
                console.log(error);
                return error;
            });
        }
    },
    delete: async (userid, jwt) => {
        return axios
            .delete(conf.strapiUrl + `/users/${userid}`, {
                headers: { Authorization: 'Bearer ' + jwt },
            })
            .then(async response => User.me(response.data.jwt))
            .catch(error => {
                console.log(error);
                return error;
            });
    }
};

export default User;
