import React from 'react';
import { View, Image } from 'react-native';
import styles from '../styles/native/styles';
import logo from '../assets/CVLogo.png';
import Link from '../components/Link.web';


const Nav = (props) => {
    return (
        <View style={styles.nav}>
            <Link to="/">
                <Image style={{height: 33, width: 179}} source={logo}/>
            </Link>
        </View>
    )
}

export default Nav;