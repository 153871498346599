import React from 'react';
import { View, Text } from 'react-native';
import styles from '../styles/native/styles';
import Link from './Link.web';


const Footer = (props) => {
    return (
        <View style={styles.footer}>
            <a href="https://www.cricketvision.co.uk/privacy-policy">
                <Text>Privacy Policy</Text>
            </a>
        </View>
    )
}

export default Footer;