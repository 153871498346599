import { createGlobalState } from 'react-hooks-global-state';
import User from '../api/user';

const { useGlobalState } = createGlobalState({
     'user': User.load()
     ,
     'activePage': 'home'
     ,
     'initialLoad': true
});

export { useGlobalState }