import React, { useState } from 'react';
import { Image, ImageBackground } from 'react-native';
import { Footer, FooterTab, Button, Text } from 'native-base';
import { navigate } from 'gatsby';
import styles from '../styles/native/styles';
import footerGradientBG from '../assets/footerGradientBG.png';
import homeIcon from '../assets/homeIcon.png';
import wicketIcon from '../assets/wicketIcon.png';
import calendarIcon from '../assets/calendarIcon.png';
import settingsIcon from '../assets/settingsIcon.png';
import { useGlobalState } from '../hooks/useGlobalState';

export default function FooterTabs() {
    const [activePage, setActivePage] = useGlobalState('activePage');
    const [user, setUser] = useGlobalState('user');
    let [buttonText1, buttonText2, buttonText3, buttonText4] = ['','','','',''];
    let [link1, link2, link3, link4] = ['','','',''];

    if (user && user.type) switch (user.type) {
        case "customer":
            [buttonText1, buttonText2, buttonText3, buttonText4] = ['Home','Book','Sessions','Account'];
            [link1, link2, link3, link4] = ['/','createjob','schedule','account'];
        break;
        case "coach":
            [buttonText1, buttonText2, buttonText3, buttonText4] = ['Home','Jobs','Schedule','Account'];
            [link1, link2, link3, link4] = ['/','availablejobs','activejobs','account'];
        break;
        case "club":
            [buttonText1, buttonText2, buttonText3, buttonText4] = ['Home','Post Job','Schedule','Account'];
            [link1, link2, link3, link4] = ['/','createjob','schedule','account'];
        break;
    }

    function handleTabPress(input) {
        console.log('TabPress Input: ' + input)
        setActivePage(input);
        navigate(input);
    }

    return (
        <ImageBackground style={styles.footerContainer} source={footerGradientBG}>
            <Footer style={styles.footerClear}>
                <FooterTab>
                    <Button vertical style={(activePage === link1) ? styles.activeFooterButton : styles.footerButton} onPress={() => handleTabPress(link1)}>
                        <Image source={homeIcon} style={{width: 25, height: 25}}/>
                        <Text uppercase={false} style={styles.footerHeading}>{buttonText1}</Text>
                    </Button>
                </FooterTab>
                <FooterTab>
                    <Button vertical style={(activePage === link2) ? styles.activeFooterButton : styles.footerButton} onPress={() => handleTabPress(link2)}>
                        <Image source={wicketIcon} style={{width: 25, height: 25}}/>
                        <Text uppercase={false} style={styles.footerHeading}>{buttonText2}</Text>
                    </Button>
                </FooterTab>
                <FooterTab>
                    <Button vertical style={(activePage === link3) ? styles.activeFooterButton : styles.footerButton} onPress={() => handleTabPress(link3)}>
                        <Image source={calendarIcon} style={{width: 25, height: 25}}/>
                        <Text uppercase={false} style={styles.footerHeading}>{buttonText3}</Text>
                    </Button>
                </FooterTab>
                <FooterTab>
                    <Button vertical style={(activePage === link4) ? styles.activeFooterButton : styles.footerButton} onPress={() => handleTabPress(link4)}>
                        <Image source={settingsIcon} style={{width: 25, height: 25}}/>
                        <Text uppercase={false} style={styles.footerHeading}>{buttonText4}</Text>
                    </Button>
                </FooterTab>
            </Footer>
        </ImageBackground>
    )
}
