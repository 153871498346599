import React, { useEffect } from 'react'
import { navigate } from 'gatsby'

import { useGlobalState } from './useGlobalState'
import User from '../api/user'

const usePageLoad = (content) => {
    const [user, setUser] = useGlobalState('user')
    const [initialLoad, setInitialLoad] = useGlobalState('initialLoad')

    const publicViews = [
        "/",
        "/login",
        "/signup",
        "/splash",
        "/forget-password",
        "/resetpassword",
        "/resetpassword/",
        "/404"
    ]

    useEffect(() => {
        if (initialLoad) setInitialLoad(false)
    }, [])

    useEffect(() => {
        if (!user && typeof window !== "undefined") {
            console.log(window.location.pathname)
            if (!publicViews.some(view => view === window.location.pathname)) {
                console.log('not on public url')
                navigate("/")
            }
        } else if (user && (user.type === "coach" || user.type === "club" || user.type === "customer") && !user.verified) {
            navigate("/splash")
        }
        (async function () {
            if (initialLoad && user) {
                if (!user.jwt) setUser(null)
                setUser(await User.me(user.jwt))
            }
        })()
    }, [user])
  
    return initialLoad ? null : content
}

export { usePageLoad }